
@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin overflow($scroll) {
    overflow-y: auto;
    overflow-y: scroll;
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none;  // IE 10+

    &::-webkit-scrollbar { // WebKit 
        width: 0;
        //background-color: var(--color-200)
    }
    &::-webkit-scrollbar-thumb {
        //background: var(--color-200)
    }
}