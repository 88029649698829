.user-list {
    .options-bar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        .user-filter {
            margin-right: 20px;
            .search-box {
                margin-right: 10px;
            }
        }
    }
}
