.task-details {
    .attachment-carousel {
        img {
            object-fit: cover;
        }
    }
    .comment-box {
        margin-top: 24px;
        .comment-label {
            color: #fff;
        }
        .comment-input {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;
            img {
                margin-right: 10px;
            }
        }
    }

    .task-activities-log {
        padding: 0 !important;
        .activity-list {
            height: unset !important;
            max-height: 350px;
        }
    }
    .comment-list {
        padding: 0 20px !important;
        .comment-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            i {
                margin-right: 5px;
            }
        }
    }
    .bottom-subtask {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        i {
            margin-right: 5px;
        }
        .list-unstyled {
            margin-bottom: unset !important;
        }
    }

    .details-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .edit-task {
            i {
                margin-right: 5px;
            }
        }
    }
}
