.my-tasks-table {
    .task-row {
        cursor: pointer;
    }
}

.task-list {
    .task-table {
        .task-name {
            min-width: 150px;
        }
        .task-description {
            width: 170px;
        }
        .task-center-cell {
            display: flex;
            justify-content: center;
        }
        .task-status {
            i {
                margin-right: 3px;
            }
        }
    }
    .task-filter {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 20px;
        .search-box {
            margin-right: 10px;
        }
    }
}
.task-description-popover {
    max-width: 400px;
}
