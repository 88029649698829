@import "./variables.scss";
@import "./assets/scss/template/main.scss";

// module user
@import "./modules/user/pages/login/index.scss";

// module project
@import "./modules/project/pages/project-list/index.scss";

// module task
@import "./modules/task/pages/my-task/index.scss";
@import "./modules/task/pages/task-details/index.scss";

// module admin
@import "./modules/admin/pages/user/index.scss";

#mytask-layout {
    -webkit-flex-direction: row;
    .itask-main-container {
        display: flex;
        flex-direction: column;
        .itask-body {
            flex: 1;
            overflow: auto;
        }
    }
    .itask-user-profile {
        cursor: pointer;
    }
}

#root {
    background-color: #fff;
}

.clickable-area {
    cursor: pointer;
}
.relative-view {
    position: relative;
}

.ant-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-modal {
        top: unset;
    }
}

.flex-row-direction {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.itask-sidebar {
    z-index: 1;
    .ant-collapse-header {
        padding: 0 !important;
        a {
            padding: 14px 0 !important;
        }
    }
    .expand-icon-itask {
        color: #fff !important;
    }

    .ant-popover {
        .ant-popover-arrow-content {
            background-color: #474d7f !important;
        }
        .ant-popover-inner {
            box-shadow: unset !important;
            background-color: #474d7f !important;
            color: #fff !important;
            .ms-link {
                .route-text {
                    color: #fff !important;
                }
            }
            .active-route {
                .route-text {
                    color: #f19828 !important;
                }
            }
        }
    }
}
.collapsed-sidebar {
    width: unset !important;
    .sidebar-container {
        align-items: center;
    }
    .collapsed-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px !important;
    }
}

.side-bar-icon {
    margin-right: 7px;
}

.user-profile {
    cursor: pointer;
}

.center-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-modal-mask {
    z-index: 98 !important;
}

.ant-modal-wrap {
    display: unset !important;
    z-index: 99 !important;
}
